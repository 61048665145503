<script>
import Swal from 'sweetalert2';


export default {
  data() {
    return {
      navIndex: 0,
      pasos: [
        { name: "Periodo", text: "Periodo" },
        { name: "PersonasNovedades", text: "Novedades" },
        { name: "NominaPreparar", text: "Preparar" },
        { name: "NominaTransmitir", text: "Transmitir" }
      ],
      liquidacionId: null,
      liquidado: false
    }
  },
  mounted() {
    this.$watch('$route', async () => {
      this.navIndex = this.pasos.findIndex(paso => {
        if (this.$route.name == 'PersonaNovedades' && paso.name == 'PersonasNovedades') {
          return true
        }
        return paso.name.includes(this.$route.name)
      })

      if (this.navIndex == 2 || this.navIndex == 3) {
        await this.consultarLiquidacion()
      }

    }, { immediate: true })
  },
  methods: {
    async consultarLiquidacion() {
      const response = await this.$store.getters.fetchGet({ path: `NominaLiquidacion/GetLiquidacionById/${this.$route.params.liquidacionId}` })
      const { id, liquidado } = await response.json()
      this.liquidacionId = id
      this.liquidado = liquidado
    },
    async next() {
      if(this.navIndex < this.pasos.length - 1) {
        if(!this.liquidado && this.navIndex == 2) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Para continuar con la transmisión de la nómina, primero debes prepararla.',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
          })
          return;
        }
        if(!this.liquidacionId && this.navIndex == 1) {
          const response = await this.$store.getters.fetchGet({ path: 'NominaLiquidacion/GetUltimaLiquidacionNoLiquidada' })
          const { id } = await response.json()
          this.liquidacionId = id 
        }

        if(this.navIndex == 0) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Para continuar con la nómina, primero debes seleccionar el periodo y guardar liquidación.',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
          })
          return
        }

        this.$router.push({ name: this.pasos[this.navIndex + 1].name, params: { liquidacionId: this.liquidacionId } })
      }
    },
    prev() {
      if(this.navIndex > 0) {
        if(this.liquidacionId && this.navIndex == 1 ) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No puedes regresar a este paso',
            confirmButtonText: 'Aceptar'
          })
          return;
        }
        this.$router.push({ name: this.pasos[this.navIndex - 1].name, params: { liquidacionId: this.liquidacionId } })
      }

    }
  }
}

</script>

<template>
  <div>
    <b-card>
      <div class="nav-container d-flex justify-content-between align-items-center">
        <div class="arrow-nav" @click="prev"
          v-if="navIndex > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
            <path
              d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0" />
          </svg>
        </div>
        <div v-else class="arrow-nav-shadow"></div>

        <div class="pasos">
          <div v-for="(paso, index) in pasos" :key="paso.name">
            <div class="wrapper-paso">
              <div class="paso" :class="{ 'currency': index === navIndex }">
                <div v-show="index >= navIndex">
                  {{ index + 1 }}
                </div>
                <div v-show="!(index >= navIndex)" class="check-button">
                  <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.837 17.837" xml:space="preserve">
                    <g>
                      <path d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z" />
                    </g>
                  </svg>
                </div>
              </div>
              <span>{{ paso.text }}</span>
            </div>
          </div>
        </div>
        <div class="arrow-nav" @click="next"
          v-if="navIndex < pasos.length - 1">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
            <path
              d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0" />
          </svg>
        </div>
        <div v-else class="arrow-nav-shadow"></div>
      </div>
    </b-card>
    <router-view></router-view>
  </div>
</template>

<style scoped>
.pasos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.wrapper-paso {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .25rem;
}

.paso {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px  var(--gray-300);
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  font-size: 2rem;
  /* color: whitesmoke; */
}

.paso.currency {
  color: var(--primary);
  border: solid 2px var(--primary)
  /* box-shadow: 0 0 10px 0 #597caf; */
}

.arrow-nav-shadow,
.arrow-nav {
  width: 5vw;
  height: 5vw;
}

.arrow-nav {
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
  border-radius: 50%;
  stroke: var(--text-progress);
  fill:  var(--text-progress);
  transition: background-color 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


.arrow-nav:hover {
  background-color: var(--gray-300);
}

.arrow-nav:first-child {
  transform: rotate(180deg);
}

.check-button {
  height: 100%;
  width: 100%;
  background: red;
  border: none;
  border-radius: 50%;
  transition: all .3s;
  position: relative;
}

.check-button svg {
  width: 100%;
  height: 100%;
  transform: scale(0.75);
  fill: var(--lego-hsuccess);
  position: absolute;
  top: 0;
  left: 0;
}
</style>